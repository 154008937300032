import { Markdown } from '@dx-ui/osc-markdown';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

import { useLanguage, useSegments } from '../../hooks';
import { getUrlHost } from '../../utils/helpers/urlBuilder';

import { Card } from './CardGroup/Card';
import { creditCardTypes, getFilteredSegmentCrediCardContent } from './helpers';

import type { OffersCreditCardGroup, OffersCreditCard } from '@dx-ui/gql-types';
type Props = {
  cardType: string;
  cards: OffersCreditCardGroup[];
};

const Paragraph = ({ children }: React.PropsWithChildren<unknown>) => (
  <p className="text-text mt-6 w-80 max-w-xs sm:w-auto">{children}</p>
);

const WelcomeOfferCardDetails = ({ cardDetails }: { cardDetails: OffersCreditCard }) => {
  const language = useLanguage();
  const [t] = useTranslation('credit-cards');

  const { attributes, offerDescription } = cardDetails;

  return (
    <div className="flex flex-col">
      <div className="mb-1 mt-6 flex items-center justify-center">
        {attributes[0] === 'welcomeOffer' ? <div className="bg-primary h-px w-full" /> : null}
        <p
          className={cx('shrink-0 py-1 px-4 text-primary font-bold', {
            'border-primary mx-auto max-w-fit rounded-full border-2 font-extrabold':
              attributes[0] === 'limitedTimeOffer',
          })}
        >
          {t(attributes[0])}
        </p>
        {attributes[0] === 'welcomeOffer' ? <div className="bg-primary h-px w-full" /> : null}
      </div>
      <Markdown
        origin={getUrlHost()}
        language={language}
        options={{ wrapper: Paragraph, forceWrapper: true }}
      >
        {offerDescription ?? ''}
      </Markdown>
    </div>
  );
};

export const WelcomeOffer = ({ cards, cardType }: Props) => {
  const segments = useSegments();
  return cards?.map((card, index) => {
    const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(card, segments);

    return (
      <Card
        key={`${cardType}-cards-welcome-offer-${creditCardContent?.cardName}`}
        card={creditCardContent}
        actionDetail={`compare:welcome:apply:${
          cardType === 'personal' ? creditCardTypes[index] : creditCardTypes[cards?.length - 1]
        }`}
      >
        <WelcomeOfferCardDetails cardDetails={creditCardContent} />
      </Card>
    );
  });
};
