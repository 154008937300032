import { useMemo, useRef, useState } from 'react';

import * as React from 'react';
import { Markdown } from '@dx-ui/osc-markdown';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import Image from 'next/legacy/image';

import Icon from '@dx-ui/osc-icon';
import { useLanguage, useSegments } from '../../hooks';
import { useCreditCardOffersQuery } from '../../queries/generated/react-query';
import { BusinessLabel, PersonalLabel } from './CardGroup/CardGroup';
import { getFilteredSegmentCrediCardContent, ImageLoader } from './helpers';

import type { TFunction } from 'i18next';
import type { OffersCreditCard, OffersCreditCardGroup } from '@dx-ui/gql-types';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';
type CardProps = {
  offersCreditCard: OffersCreditCard;
  t: TFunction<'credit-cards'>;
};

const {
  publicRuntimeConfig: { REACT_APP_BRAND_HOST },
} = getConfig();

const Card = ({ offersCreditCard, t }: CardProps) => {
  const { feeDescription, cardName, images, offerTitle, attributes } = offersCreditCard;
  const language = useLanguage();
  return (
    <>
      {images && images[0]?.image ? (
        <div className="focus-within:outline-primary relative min-h-[135px] max-w-[224px] focus-within:outline focus-within:outline-2 focus-within:outline-offset-2 ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2">
          <a className="focus:!shadow-none" href={`#card-${cardName}`}>
            <Image
              src={images[0]?.image?.image?.variants?.tabs[0]?.cdnLink || ''}
              alt={images[0]?.altText || ''}
              loader={ImageLoader}
              layout="fill"
              objectFit="contain"
            />
          </a>
        </div>
      ) : null}
      <h4 className="text-text mt-3 text-base font-extrabold md:text-lg lg:row-start-2">
        {cardName ? cardName : null}
      </h4>
      <p className="text-text text-sm md:text-base lg:row-start-3">
        {feeDescription ? feeDescription : null}
      </p>
      <div className="flex items-center justify-center">
        {attributes[0] === 'welcomeOffer' ? <div className="bg-primary h-px w-full" /> : null}
        <p
          className={cx('my-4 shrink-0 py-1 px-2 font-bold text-primary', {
            'mx-auto max-w-fit rounded-full border border-primary':
              attributes[0] === 'limitedTimeOffer',
          })}
        >
          {t(attributes[0])}
        </p>
        {attributes[0] === 'welcomeOffer' ? <div className="bg-primary h-px w-full" /> : null}
      </div>
      {offerTitle ? (
        <p>
          <Markdown
            language={language}
            origin={REACT_APP_BRAND_HOST}
            options={{
              overrides: {
                strong: {
                  props: {
                    className: 'text-primary',
                  },
                },
              },
              wrapper: React.Fragment,
              forceWrapper: true,
            }}
          >
            {offerTitle}
          </Markdown>
        </p>
      ) : null}
    </>
  );
};

export const CardDisplay = () => {
  const [t] = useTranslation('credit-cards');
  const language = useLanguage();
  const isRtlLanguage = isRtl(language);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const segments = useSegments();

  const handleScroll = ({ target }: React.ChangeEvent<HTMLDivElement>) => {
    setScrollLeft(target.scrollLeft);
  };

  useIsomorphicLayoutEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll as unknown as EventListener);
      return () =>
        (wrapperRef.current as HTMLDivElement).removeEventListener(
          'scroll',
          handleScroll as unknown as EventListener
        );
    }
  }, [wrapperRef]);

  const scrollAmount = isRtlLanguage ? -scrollLeft : scrollLeft;

  const { data } = useCreditCardOffersQuery({ language });
  const creditCardsContent = data?.creditCardOffers as OffersCreditCardGroup[];

  const displayContent = useMemo(() => {
    const personalCards: OffersCreditCardGroup[] =
      creditCardsContent?.filter((card) => card?.cardType === 'personal') || [];
    const businessCards: OffersCreditCardGroup[] =
      creditCardsContent?.filter((card) => card?.cardType === 'business') || [];

    return (
      <section className="mt-2 lg:mt-12 lg:flex lg:gap-8 lg:px-6">
        <div
          className="flex flex-wrap overflow-x-auto lg:block lg:overflow-visible"
          ref={wrapperRef}
        >
          <div className="bg-bg/100 lg:border-text-alt relative ml-6 mt-6 grid shrink grow-0 grid-cols-[repeat(3,minmax(180px,1fr))] gap-x-8 rounded-xl p-8 text-center lg:m-0 lg:h-full lg:border rtl:mr-5">
            <PersonalLabel />
            {Array.isArray(personalCards) &&
              personalCards.length > 0 &&
              personalCards?.map((personalItem) => {
                const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(
                  personalItem,
                  segments
                );
                return (
                  <div key={`card-wrapper-${creditCardContent?.cardName}`}>
                    <Card offersCreditCard={creditCardContent} t={t} />
                  </div>
                );
              })}
          </div>
        </div>
        <section className="text-text-inverse relative mr-2 py-3 pr-3 text-right sm:hidden">
          {scrollAmount < 100 ? (
            <>
              <Icon
                name="arrowhead-right"
                className="absolute bottom-0.5 right-14 rotate-180 ltr:hidden"
                size="sm"
              />
              <span className="border-text-inverse inline-block border-b pr-4 text-left">
                {t('more')}
              </span>
              <Icon
                name="arrowhead-right"
                className="absolute bottom-0.5 right-0 rtl:hidden"
                size="sm"
              />
            </>
          ) : (
            <span aria-hidden="true">&nbsp;</span>
          )}
        </section>

        <div className="mt-8 flex pl-6 lg:m-0 lg:p-0">
          <div className="bg-bg/100 lg:border-text-alt relative grid grid-cols-[repeat(1,minmax(180px,max-content))] rounded-xl p-8 text-center lg:border rtl:mr-5">
            <BusinessLabel />
            {Array.isArray(businessCards) &&
              businessCards.length > 0 &&
              businessCards?.map((businessCard) => {
                const creditCardContent: OffersCreditCard = getFilteredSegmentCrediCardContent(
                  businessCard,
                  segments
                );
                return (
                  <div key={`card-wrapper-${creditCardContent?.cardName}`}>
                    <Card offersCreditCard={creditCardContent} t={t} />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    );
  }, [creditCardsContent, t, scrollAmount, segments]);

  return displayContent;
};
